import styled from "styled-components"

export const StyledHTML = styled.div`
  ul,
  ol {
    margin-bottom: 0.5rem;
    li {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  section {
    display: flex;
    flex-direction: column;
  }
  h2 {
    scroll-margin-top: 6rem;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.color.indigo600};
  .embed-container {
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  p .klipfolio-image-wrapper,
  li .klipfolio-image-wrapper {
    margin-bottom: 0 !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5;
    margin: 0;
    margin-bottom: 1.5rem;
  }
  table + h2,
  p + h2,
  ol + h2,
  p + .klipfolio-image-wrapper,
  ul + h2,
  ul + .klipfolio-image-wrapper {
    margin-top: 2.5rem;
  }
  p + h3,
  ul + h3,
  ol + h3 {
    margin-top: 1.5rem;
  }

  h2 + h3 {
    margin-top: 0;
  }
  h1 {
    font-size: 300%;
    line-height: 1.3;
  }
  h2 {
    font-size: 240%;
  }
  h3 {
    font-size: 180%;
    font-weight: 600;
  }
  blockquote {
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0;
      ::before {
        content: "“";
      }
      ::after {
        content: "”";
      }
      color: ${({ theme }) => theme.color.indigo400};
      font-weight: 600;
      text-align: center;
      font-size: 180%;
      line-height: 1.5;
    }
  }
  table {
    font-size: 1.2rem;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    td {
      line-height: 1.42857143;
    }
    td:first-child {
      padding-left: 0;
    }
    .table > thead > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > th,
    .table > tbody > tr > td,
    .table > tfoot > tr > th,
    .table > tfoot > tr > td {
      padding: 8px;
      vertical-align: top;
      border-top: 1px solid #ddd;
    }
  }
`
